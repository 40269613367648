<template>
	<div class="slider-container">
		<input class="slider-input" type="range" value="0" min="0" max="100" step="1" list="my-detents"
			v-model="sliderValue" @input="updateSliderWidth" />
		<div class="slider-track">
			<div class="slider-track-thumb" :style="{ width: thumbWidth }"></div>
			<div class="slider-dot" :class="{ active: sliderValue >= 0 }">
				<div class="label">0%</div>
			</div>
			<div class="slider-dot" style="left: 25%" :class="{ active: sliderValue >= 25 }">
				<div class="label" style="left: -1px">25%</div>
			</div>
			<div class="slider-dot" style="left: 49%" :class="{ active: sliderValue >= 50 }">
				<div class="label" style="left: -2px;">50%</div>
			</div>
			<div class="slider-dot" style="left: 73%" :class="{ active: sliderValue >= 75 }">
				<div class="label" style="left: -4px">75%</div>
			</div>
			<div class="slider-dot" style="left: 97.8%" :class="{ active: sliderValue >= 100 }">
				<div class="label" style="left: -16px;">100%</div>
			</div>
		</div>

		<datalist id="my-detents">
			<option value="25"></option>
			<option value="50"></option>
			<option value="75"></option>
		</datalist>
	</div>
</template>

<script>
export default {
	name: 'Slider',
	data() {
		return {
			sliderValue: 0,
			thumbWidth: '0%',
		}
	},
	props: {
		value: { // 부모로부터 받은 값
			type: Number,
			required: true,
		},
	},
	watch: {
		value: {
			handler(newVal) {
				this.sliderValue = Number(newVal);
				this.thumbWidth = `${this.sliderValue}%`;
			}
		}
	},
	computed: {
		// thumbPosition() {
		//   // Calculate the left position based on slider value
		//   return `${this.sliderValue}%`;
		// }
	},
	methods: {
		updateSliderWidth() {
			// Calculates the width of the thumb based on the slider value
			this.thumbWidth = `${this.sliderValue}%`;
			this.$emit('update', this.sliderValue)
		},
	},
};
</script>

<style lang="scss" scoped>
.slider-container {
	width: 100%;
	position: relative;
	height: 25px;
	margin: 10px 0 30px 0;

	/* border: 1px solid red; */
	.slider-input {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		-webkit-appearance: none;
		width: 100%;
		height: 0;
		border-radius: 5px;
		/* background: #d3d3d3; */
		outline: none;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 11px;
			height: 11px;
			border-radius: 50%;
			background: white;
			border: 2px solid var(--btn-primary);
			cursor: pointer;
			margin-top: -17px;
		}

		&::-ms-thumb {
			margin-top: 0;
		}
	}

	.slider-track {
		position: absolute;
		top: 11px;
		width: 100%;
		height: 2px;
		background-color: #dddddd;

		.slider-track-thumb {
			width: 100%;
			height: 3px;
			background-color: var(--btn-primary);
		}

		.slider-dot {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 8px;
			height: 8px;
			border-radius: 50vh;
			background-color: #dddddd;

			&.active {
				background-color: var(--btn-primary);
			}

			.label {
				position: absolute;
				top: 20px;
				/* left: 50%; */
				/* transform: translateX(-50%); */
				font-size: 10px;
			}
		}
	}
}
</style>
