<template>
  <div>
    <modal name="modalTPSlAll" classes="main-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header">
        <h2 class="modal-header-title">전체 포지션 이익실현(TP)/스탑로스(SL)</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-tpsl-all">
          <table class="modal-tpsl-all-table">
            <tbody>
              <tr>
                <td>
                  <div class="label">심볼</div>
                  <div class="value"><span class="orange">ETH/USDT 무기한 100x</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">진입가격</div>
                  <div class="value">2,123.23 USDT</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">시장평균가</div>
                  <div class="value">2,123.23 USDT</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">이익실현(TP)</label>
              <input type="number" placeholder="이익실현(TP)" value="0" />
              <div class="unit">시장평균</div>
            </div>
            <p><span class="blue">시장평균가</span>가 <span class="blue">0</span>에 도달하면 이익실현 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다. <span class="red">-USDT</span></p>
          </div>

          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">스탑로스(SL)</label>
              <input type="number" placeholder="이익실현(TP)" value="0" />
              <div class="unit">시장평균</div>
            </div>
            <p><span class="blue">시장평균가</span>가 <span class="blue">0</span>에 도달하면 이익실현 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다. <span class="blue">-USDT</span></p>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary blue" @click="closeModal()">TP 취소</button>
        <button class="btn-primary blue" @click="closeModal()">SL 취소</button>
        <button class="btn-primary blue" @click="closeModal()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CoinModalTPSLAll',

  data() {
    return {
      width: '',
      height: '',
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 452;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalTPSlAll');
    },
  },
};
</script>
<style lang="scss" scoped></style>
