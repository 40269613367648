<template>
  <div class="buysell-scale">
    <div class="buy-label">
      B
      <span>{{ calculateWidth(calcBid) }} </span>
    </div>
    <div class="scale-bar">
      <div class="buy-scale" :style="{ width: calculateWidth(calcBid) }"></div>
      <div class="sell-scale" :style="{ width: calculateWidth(calcAsk) }"></div>
    </div>
    <div class=" sell-label">
      <span>{{ calculateWidth(calcAsk) }}</span>
      S
    </div>
  </div>
</template>

<script>
import { stringCount } from '@/modules/helper';

export default {
  name: 'buysellScaleBar',
  components: {
  },
  computed: {
    askBidMax() {
      //viewCnt +1 라인부터 , viewCnt +1 + viewCnt 목록개수보다 적으면 .asksListlength
      let first = 0;
      let last = 20;
      const maxlen = this.asksList.length;
      //16 < 20
      if (this.viewCnt < this.asksList.length) {
        first = this.asksList.length - this.viewCnt + 1;
        //17 + 16 ...
        //9 ~ 20
      }
      //return this.viewCnt ? this.asksList.slice(this.viewCnt+1, this.viewCnt+1+this.viewCnt < this.asksList.length ? this.viewCnt+1+this.viewCnt : this.asksList.length) : this.asksList
      const ask = this.viewCnt ? this.asksList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.asksList
      const bid = this.viewCnt ? this.bidsList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.bidsList

      let maxval = 0
      let maxval2 = 0
      ask.forEach(item => {
        if (maxval < Number(item.sum)) {
          maxval = Number(item.sum)

        }
      })

      bid.forEach(item => {
        if (maxval2 < Number(item.sum)) {
          maxval2 = Number(item.sum)
        }
      })

      return maxval + maxval2
    },
    calcAsk() {
      //viewCnt +1 라인부터 , viewCnt +1 + viewCnt 목록개수보다 적으면 .asksListlength
      let first = 0;
      let last = 20;
      const maxlen = this.asksList.length;
      //16 < 20
      if (this.viewCnt < this.asksList.length) {
        first = this.asksList.length - this.viewCnt + 1;
        //17 + 16 ...
        //9 ~ 20
      }
      //return this.viewCnt ? this.asksList.slice(this.viewCnt+1, this.viewCnt+1+this.viewCnt < this.asksList.length ? this.viewCnt+1+this.viewCnt : this.asksList.length) : this.asksList
      const ask = this.viewCnt ? this.asksList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.asksList
      let maxval = 0
      ask.forEach(item => {
        if (maxval < Number(item.sum)) {
          maxval = Number(item.sum)

        }
      })
      return maxval
    },
    calcBid() {
      const bid = this.viewCnt ? this.bidsList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.bidsList

      let maxval = 0
      bid.forEach(item => {
        if (maxval < Number(item.sum)) {
          maxval = Number(item.sum)
        }
      })

      return maxval
    },
    // 로그인 여부
    isLogin() {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓 코인 시세
    changeCocData: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function () {
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData
    },
    //소켓 코인 센터가
    changeCotData: function () {
      let cotData = window.$store.getters['SocketCoinPrice/getCotData']
      return cotData
    },
    //소켓 코인 청산가
    changeComData: function () {
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    getSelectCoinSymbol: function () {
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice) {
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.coinSymbol.name) {
        this.cot = newPrice
      }
      //
    },
    //소켓호가
    changeCohData(newPrice) {
      // apiType
      // asks
      // bids
      // hotTime
      // symbol
      if (newPrice.symbol == this.coinSymbol.name) {
        this.updateCoh(newPrice);
      }
    },
    //소켓 코인 센터가
    changeCotData(newPrice) {
      if (newPrice.symbol == this.coinSymbol.name) {

        // apiType: "COT"
        // buyerMaker: false
        // curPr: 61094.7
        // korDate: "20241011"
        // korTm: "191003"
        // qty: 0.017
        // symbol: "BTCUSDT"
        // trdTm: 1728641403276


        //this.cot = newPrice
      }
    },
    //소켓 코인 청산가
    changeComData(newPrice) {
      // apiType
      // korDate
      // korTm
      // markPr
      // rate
      // symbol
      // trdTm
      if (newPrice.symbol == this.coinSymbol.name) {
        this.com = newPrice
      }
    },
    //폴링시세
    changeOvcDataList(newPrice) {
      //
    },
    //폴링호가
    changeOvhDataList(newPrice) {
      //
    },
    getSelectCoinSymbol(newVal) {
      if (newVal == undefined) return;

      this.coinSymbol = newVal;

      this.bidsList = [];
      this.asksList = [];
      this.cot.curPr = 0;
      this.com.markPr = 0;
    },
  },
  data() {
    return {
      showTick: false,
      cot: {
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      com: {
        markPr: 0,
      },
      bidsList: [],
      asksList: [],
      viewCnt: 8,
      coinSymbol: null,
      tikSize: 0,
      orderBookMode: "1",
    }
  },
  created() {
    //ordersetting 호출 후 buy sell 색상 설정
  },
  mounted() {
  },
  methods: {
    calculateWidth(sum) {
      const percentage = (sum / this.askBidMax) * 100;
      return `${percentage.toFixed(2)}%`; // 결과를 'px', '%' 등 단위로 반환
    },
    showTickList() {
      this.showTick = !this.showTick;
      console.log("showTick", this.showTick)
    },
    selectRowItem(item) {
      this.$emit("updateCoinPrice", item);
    },
    updateCoh(newPrice) {
      let asksList = [];
      let askSum = 0;
      let revsAskList = [];

      //ask
      if (newPrice.asks != undefined) {
        //sum값 계산, 배열 순번이 뒤로 갈수록 합산
        newPrice.asks.forEach(item => {
          askSum += Number(item[1]);
          item[2] = askSum.toFixed(3);
        })

        //리스트 리버스
        // for (let i=newPrice.asks.length-1; i>=0;i--){
        //   let item = newPrice.asks[i];
        //   asksList.push({price:item[0], size:item[1], sum:item[2]});
        // }
        for (let i = 0; i < newPrice.asks.length; i++) {
          let item = newPrice.asks[i];
          asksList.push({ price: item[0], size: item[1], sum: item[2] });
        }

        this.asksList = asksList;
      }

      //bid
      if (newPrice.bids != undefined) {
        let bidsList = [];
        let bidSum = 0;
        newPrice.bids.forEach(item => {
          bidSum += Number(item[1]);
          bidsList.push({ price: item[0], size: item[1], sum: bidSum.toFixed(3) });
        });

        this.bidsList = bidsList;
      }
    },
    chnageOrderBook(type) {
      if (this.orderBookMode == type) return;

      this.orderBookMode = type;
      if (type == "1") { //매도 매수 분할 표시
        this.viewCnt = 8;
      } else if (type == "2") { //매도만 표시
        this.viewCnt = 18;
      } else if (type == "3") { //매수만 표시
        this.viewCnt = 18;
      }
    }
  },

}
</script>