<template>
  <div>
    <modal name="modalDepositWithdraw" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">입출금신청</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'deposit' }" @click="selectedTab = 'deposit'">입금신청</div>
          <div class="tab" :class="{ active: selectedTab === 'withdrawal' }" @click="selectedTab = 'withdrawal'">출금신청</div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'deposit'">
          <div class="tab-inner">
            <div class="col2-grid">
              <div class="left">
                <h3 class="subtitle">입금계좌</h3>
                <div class="input-row">
                  <label for="" class="input-label">은행명</label>
                  <h4>{{ bankCpn.bnkNm }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">계좌번호</label>
                  <h4>{{ bankCpn.bnkAccNo }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">예금주</label>
                  <h4>{{ bankCpn.bnkAccHd }}</h4>
                </div>
              </div>
              <div class="division-line mobile"></div>
              <validation-observer ref="validatorDeposit">
              <div class="right">
                <validation-provider rules="required|amount|limit:13" name="입금신청금액" v-slot="{errors}">
                <h3 class="subtitle">신청금액</h3>
                <div class="input-row wts-margin-row" :class="{'margin-row-bottom':errors[0] != null}">
                  <label for="dpsReqMn" class="input-label label-margin">입금신청금액</label>                 
                    <div class="input-unit">
                      <input type="text" id="dpsReqMn" :value="depositForm.dpsReqMn" @input="depositForm.dpsReqMn = checkValue($event)" maxlength="13" />
                      <div class="unit">원</div>
                      <div class="error errPadding">{{errors[0] }}</div>
                    </div>                      
                </div>
                </validation-provider>
                <div class="input-row">
                  <label for="dpsHd" class="input-label label-margin">입금자명</label>
                  <div class="input-unit">
                    <validation-provider rules="required|limit:15" name="입금자명" v-slot="{errors}">
                      <input type="text" id="dpsHd" v-model="depositForm.dpsHd" />
                      <div class="error">{{errors[0] }}</div>
                    </validation-provider>
                  </div>  
                </div>
                <div class="flex-row row-margin">
                  <button class="btn-line" @click="sumDpsReqMn(100_0000)">+100만</button>
                  <button class="btn-line" @click="sumDpsReqMn(50_0000)">+50만</button>
                  <button class="btn-line" @click="sumDpsReqMn(10_0000)">+10만</button>
                  <button class="btn-line" @click="sumDpsReqMn(1_0000)">+1만</button>
                  <button class="btn-line" @click="sumDpsReqMn(0)">초기화</button>
                </div>
              </div>
              </validation-observer>
            </div>
          </div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'withdrawal'">
          <div class="tab-inner">
            <div class="col2-grid">
              <div class="left">
                <h3 class="subtitle">출금계좌</h3>
                <div class="input-row">
                  <label for="" class="input-label">은행명</label>
                  <h4>{{ bankWd.wdBnkNm }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">계좌번호</label>
                  <h4>{{ bankWd.wdBnkAccNo }}</h4>
                </div>
                <div class="input-row">
                  <label for="" class="input-label">예금주</label>
                  <h4>{{ bankWd.wdBnkHd }}</h4>
                </div>
              </div>
              <div class="division-line mobile"></div>
              <div class="right">
                <h3 class="subtitle">신청금액</h3>
                <div class="input-row">
                  <label for="" class="input-label">출금가능금액</label>
                  <div class="input-unit">
                    <input type="text" :value="balMn" readonly="readonly" />
                    <div class="unit">원</div>
                  </div>
                </div>
                <validation-observer ref="validatorDeposit">
                <validation-provider rules="required|amount|limit:13" name="출금신청금액" v-slot="{errors}">
                <div class="input-row wts-margin-row" :class="{'margin-row-bottom':errors[0] != null}">
                  <label for="" class="input-label label-margin">출금신청금액</label>
                  <div class="input-unit">
                    <input type="text" :value="withdrawForm.wdReqMn" @input="withdrawForm.wdReqMn = checkValue($event)" maxlength="13" />
                    <div class="unit">원</div>
                    <div class="error errPadding">{{errors[0] }}</div>
                  </div>
                </div>
                </validation-provider>
                </validation-observer>
                <div class="flex-row row-margin">
                  <button class="btn-line" @click="sumWdReqMn(100_0000)">+100만</button>
                  <button class="btn-line" @click="sumWdReqMn(50_0000)">+50만</button>
                  <button class="btn-line" @click="sumWdReqMn(10_0000)">+10만</button>
                  <button class="btn-line" @click="sumWdReqMn(1_0000)">+1만</button>
                  <button class="btn-line" @click="sumWdReqMn(0)">초기화</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="division-line"></div>

        <div class="notice-container">
          <h3 class="subtitle">유의사항</h3>
          <ul v-if="selectedTab === 'deposit'">
            <li>당사의 입금계좌가 보이지 않을 경우 반드시 고객센터로 연락하시기 바랍니다.</li>
            <li>입금신청금액과 실제 입금금액이 다를 경우 입금처리가 거부될 수 있음을 유의하시기 바랍니다.</li>
            <li>등록해주신 계좌이외에 타인의 명의로 입금시 입금처리가 거부되오니 반드시 입금전에 확인해주시기 바랍니다.</li>
          </ul>
          <ul v-if="selectedTab === 'withdrawal'">
            <li>보유잔고가 존재하면 출금신청이 불가능합니다. (미체결, 체결)</li>
            <li>등록해주신 계좌가 존재하지 않으면 출금이 불가능합니다.</li>
            <li>출금신청 처리 시간은 평균 5분 내외이나 입출금신청이 빈번한 시간에는 출금 처리가 지연될 수 있습니다.</li>
          </ul>
        </div>
      </div>


      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="closeModal()">취소</button>
        <button class="btn-primary blue" v-if="selectedTab === 'deposit'" @click="requestDeposit">입금신청</button>
        <button class="btn-primary blue" v-if="selectedTab === 'withdrawal'" @click="requestWithdraw">출금신청</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
export default {
  name: 'WhiteLabelModalDepositWithdraw',
  computed:{
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  data() {
    return {
      width: '',
      height: '',
      selectedTab: 'deposit',
      tabList: [{name:"입금신청", id:"deposit"},{name:"출금신청", id:"withdrawal"}],
      showSearchBalMn: false,
      account: {
        accNo: '',
        accId: 0,
      },
      bankCpn: {},
      bankWd: {},
      balMn: 0,
      depositForm: {
        accId: 0,
        accPass: '',
        dpsReqMn: 0,
        dpsHd: ''
      },
      withdrawForm: {
        accId: 0,
        accPass: '',
        wdReqMn: 0, 
        wdBnkNm: '',
        wdBnkAccNo: '',
        wdBnkHd: '',
      }, 
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {
	},

  methods: {
    checkValue(event){
      return Utils.inputComma(event);
    },
    closeModal() {
      this.$modal.hide('modalDepositWithdraw');
    },
    opened(){
      this.searchBalMn();
    },
    beforeOpen(e){
      this.account = Object.assign({}, e.params.account);

      this.searchBankCpn();
      this.searchBankWd();   
      this.selectedTab = 'deposit';
      this.balMn = 0;
      this.depositForm.accId = 0;
      this.depositForm.accPass = '';
      this.depositForm.dpsReqMn = 0;
      this.depositForm.dpsHd = '';
      this.withdrawForm.accId = 0;
      this.withdrawForm.accPass = '';
      this.withdrawForm.wdReqMn = '';
      this.withdrawForm.wdBnkNm = '';
      this.withdrawForm.wdBnkAccNo = '';
      this.withdrawForm.wdBnkHd = '';
    },
    sumDpsReqMn(sumValue) {
      if ( sumValue == 0 ) {
        this.depositForm.dpsReqMn = 0;
      } else {
        let dpsReqMn = '';
        if ( this.depositForm.dpsReqMn === '' ) {
          dpsReqMn = sumValue;
        } else {
          if (this.depositForm.dpsReqMn != undefined && this.depositForm.dpsReqMn != "0"){
            dpsReqMn = parseInt(this.depositForm.dpsReqMn.split(',').join('')) + sumValue;
          }else{
            dpsReqMn = sumValue;
          }
        }

        this.depositForm.dpsReqMn = Utils.numberComma(dpsReqMn);
      }
    },
    sumWdReqMn(sumValue) {
      if ( sumValue == 0 ) {
        this.withdrawForm.wdReqMn = 0;
      } else {
        let wdReqMn = '';
        if ( this.withdrawForm.wdReqMn === '' ) {
          wdReqMn = sumValue;
        } else {
          if (this.withdrawForm.wdReqMn != undefined && this.withdrawForm.wdReqMn != "0"){
            wdReqMn = parseInt(this.withdrawForm.wdReqMn.split(',').join('')) + sumValue;
          }else{
            wdReqMn = sumValue;
          }
          
        } 
        
        this.withdrawForm.wdReqMn = Utils.numberComma(wdReqMn);
      }
    },
    async searchBankCpn() {
      const response = await this.$lhttp.get('/api/account/bank/cpn');

      if ( response && response.data != null ) {
        this.bankCpn = response.data;
      }        
    },
    async searchBankWd() {
      const response = await this.$lhttp.get('/api/account/bank/wd');

      if ( response && response.data != null ) {
        this.bankWd = response.data;
      }        
    },
    async searchBalMn() {
      const response = await this.$lhttp.get('/api/account/balmn', {params:{accId: this.account.accId}});

      if ( response && response.data != null ) {
        let balMn = 0;
        if (response.data != undefined && response.data > 0){
          
          balMn = Utils.numberComma(response.data);
        }
        this.balMn = balMn;
      }        
    },
    requestDeposit() {
      const { validatorDeposit } = this.$refs;

      validatorDeposit.validate().then((result) => {
        if (result) {

          // if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
          //   this.$alert.alert('입금 신청 할 수 없는 계좌입니다.', '확인')
          //   return
          // }

          if (Number(this.depositForm.dpsReqMn.split(',').join('')) > 5000000000){
            this.$alert.alert('입금 최대 금액은 50억원 입니다.', '확인');
            return;
          }

          this.$alert.confirm('입금 신청 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                
                this.depositForm.accId   = this.account.accId;
                //this.depositForm.accPass = this.account.accPass;

                let depositForm = this.depositForm;
                depositForm.dpsReqMn = depositForm.dpsReqMn.split(',').join('');

                this.$lhttp
                  .post('/api/account/request/deposit',
                  depositForm)
                  .then(() => {
                    this.$alert.alert('입금 신청이 완료되었습니다.', '안내').then(() => {
                      this.$emit("depositWithdraw-complete");
                      this.closeModal()
                    })
                  })

              }
            })

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', '알림')            
        }
      })
    },
    requestWithdraw() {
      const { validatorDeposit } = this.$refs;

      validatorDeposit.validate().then((result) => {
        if (result) {

          if (this.account.accId == undefined || this.account.accId == 0){
            this.$alert.alert('계좌정보가 없습니다.', '안내')
            return;
          }

          if ( this.withdrawForm.wdReqMn <= 0 ) {
            this.$alert.alert('먼저 출금신청금액을 확인 하십시오.', '알림')
            return
          }

          if ( this.bankWd.wdBnkAccNo === undefined || this.bankWd.wdBnkAccNo === null || this.bankWd.wdBnkAccNo === '' 
           || this.bankWd.wdBnkNm === undefined || this.bankWd.wdBnkNm === null || this.bankWd.wdBnkNm === '' 
           || this.bankWd.wdBnkHd === undefined || this.bankWd.wdBnkHd === null || this.bankWd.wdBnkHd === '' 
          ) {
            this.$alert.alert('먼저 출금계좌 정보를 등록 하십시오.', '알림')
            return
          }

          if (this.balMn == 0){
            this.$alert.alert('출금신청 금액이 출금가능금액을 초과할 수 없습니다.', '알림')
            return
          }else{
            if (this.balMn != 0 && this.balMn != "0" && Number(this.balMn.split(",").join("")) < this.withdrawForm.wdReqMn.split(',').join('')){
              this.$alert.alert('출금신청 금액이 출금가능금액을 초과할 수 없습니다.', '알림')
              return
            }
          }
          

          // if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
          //   this.$alert.alert('출금 신청 할 수 없는 계좌입니다.', '확인')
          //   return
          // }

          //포지션 체크
          const positionList = window.$store.getters['SocketOms/getPosOrdStList'];
          if (positionList != undefined && positionList.length > 0){
            let balQty = 0;
            let ordQty = 0;
            let mitQty = 0;
            positionList.forEach(data=>{
              if (data.ordType == "POS"){
                balQty += data.balQty;
              }else if (data.ordType == "ORD"){
                ordQty += data.balQty;
              }else if (data.ordType == "ST"){
                mitQty += data.balQty;
              }
            });

            if (balQty > 0 || ordQty > 0 || mitQty> 0){
              this.$alert.alert('보유 포지션 또는 미체결 주문이 있어 출금 신청 하실 수 없습니다.', '알림');
              return;
            }
          }

          this.$alert.confirm('출금 신청 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {      

                this.withdrawForm.accId      = this.account.accId
                this.withdrawForm.accPass    = this.account.accPass
                this.withdrawForm.wdBnkNm    = this.bankWd.wdBnkNm
                this.withdrawForm.wdBnkAccNo = this.bankWd.wdBnkAccNo
                this.withdrawForm.wdBnkHd    = this.bankWd.wdBnkHd

                let withdrawForm = this.withdrawForm;
                withdrawForm.wdReqMn = withdrawForm.wdReqMn.split(',').join('');

                this.$lhttp
                  .post('/api/account/request/withdraw',
                      withdrawForm)
                  .then(() => {
                    this.$alert.alert('출금 신청이 완료되었습니다.', '안내').then(() => {
                      this.$emit("depositWithdraw-complete");
                      this.closeModal()
                    })
                  })

              }
            })  
        }
      })
    },
    popWdBank() {
      this.$modal.show('wdBankPop')
    }           
  },
};
</script>

<style scoped>
.input-row-error {
  display: grid;
  grid-template-columns: 80px 200px;
  align-items: center;
  gap: 0 30px;
}

.error {
  color: var(--table-red);
  font-size: 12px;
  width:225px;
  margin-top: 5px;
}

.errPadding{
  /* padding-bottom:5px;
  width: 124%; */
  position: absolute;
  transform: translateY(125%);
}

.label-margin{
  align-self: baseline;
  margin-top: 10px;
}

.row-margin{
  margin-top: 20px;
}

.wts-margin-row{
  margin-bottom: 10px;
}

.margin-row-bottom{
  margin-bottom: 32px;
}
</style>

