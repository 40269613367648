<template>
  <div class="ag-instrument">
    <div class="instrument">{{ params.data.symCd }}</div>
    <div class="margin">{{ params.data.marginMode == undefined ? '' : params.data.marginMode == '1' ? "CROSS" : "ISOLATED" }} {{ params.data.lv }}x</div>
  </div>
</template>

<script>
export default {
  name: 'agInstrument',
  components: {
  },
  computed: {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  created(){
  },
  mounted() {

  },
  methods:{

  },

}
</script>

<style lang="scss" scoped>
.ag-instrument {
  text-align: center;
  font-size: 12px;
	line-height: 12px;
	.margin {
		font-size: 10px;
		color: #f67c32;
	}
}
</style>
